export default {
    Nav: {
        Home: '官网首页',
        About: '关于我们',
        Game: '游戏产品',
        Joinus: '加入我们',
        contactUs: '联系我们',
        secret:'隐私协议',
        terms:'服务条款',
        safe:'账户安全'
    },
    Footer: {
        cont: "Copyright {'@'} 2023 All Right Reserved 悠樂網絡科技有限公司 版权所有",
    },
    commencont:{
        Coming: '敬请期待'
    },
    homecont: {

    },
    contactus: {
        address:'新界屯門天后路1虢彩星大厦1期9樓',
        title:'悠樂網絡科技有限公司'
    },
    Aboutus:{
        about1: '悠樂網絡有限公司成立于2023年5月，总部位于中国香港，是一家以手机游戏为核心，集研发、运营、发行为一体的综合性互动娱乐企业。',
        about2: '公司核心团队成员来自于全球知名游戏企业，拥有丰富的游戏研发和运营经验，一直致力于以自主创新为基石，凭借雄厚的技术实力和创意游戏设计能力，多款卡牌、策略、MMORPG、休闲游戏正在紧锣密鼓研发中，即将发行中国港澳台地区、日本、韩国、欧州、中东、东南亚等全球市场。',
        about3: '未来，悠樂游戏将继续开发和发布精品游戏产品，超越用户的期望，为全球各地的玩家带来有趣、令人兴奋的娱乐体验和优质服务。',
    },
    title:{
        title: '悠樂網絡 - 全球手机游戏发行与研发商',
        Keywords: '悠樂網絡,手机游戏,網絡游戏,mobile game,安卓游戏,苹果游戏,ios游戏,android游戏,网络游戏,发行商,海外游戏,香港游戏,谷歌商店,苹果商店,下载,安装,免费游戏.',
        Description:'一家手机游戏研发与发行商。'
    },
    Secret:{
        src:'/static/policy.html'
    },
    Terms:{
        src:'/static/terms.html'
    },
    Account:{
        line1:'如果您要删除相关的账户信息，请填写以下信息（账户一旦删除无法恢复）',
        line2:'提示：如果您是谷歌账号或Facebook账号登录，则需去游戏内悬浮窗创建账号，再填写账号密码',
        line3:"发送uid/account/password信息至客服邮箱crm{'@'}youlefun.com",
        form1:'请输入uid',
        form2:'请输入账户',
        form3:'请输入密码',
        btn2:'删除数据',
        btn3:'取消',
        btn4:'确认'
    }
}
