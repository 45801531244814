<template>
  <div class="homecss">
    <el-carousel class="carouselimg">
      <el-carousel-item v-for="item in 4" :key="item">
        <el-image :src="img" :fit="fit" />
      </el-carousel-item>
    </el-carousel>
    <div class="Aboutus">
      <div class="w1200">
        <div class="indexleft">
          <p>
            {{ $t(`Aboutus.about1`) }}
          </p>
          <p>
            {{ $t(`Aboutus.about2`) }}
          </p>
          <p>
            {{ $t(`Aboutus.about3`) }}
          </p>
        </div>
        <div class="indexright">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? autoimgen : autoimg"
          />
        </div>
      </div>
    </div>
    <div class="WeGame">
      <div class="po">
        <div class="WeGame_left">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? gameimgen : gameimg"
          />
        </div>
        <div class="WeGame_right">
          <ul>
            <li>
              <el-image class="imgw" :src="require('@/assets/xulie/1.jpg')" />
              <!-- <el-image class="imgw" :src="require('@/assets/blank.png')" /> -->
            </li>
            <li>
              <el-image class="imgw" :src="require('@/assets/xulie/2.jpg')" />
              <!-- <el-image class="imgw" :src="require('@/assets/blank.png')" /> -->
              <!-- <span>{{ $t(`commencont.Coming`) }}</span> -->
            </li>
            <li>
              <el-image class="imgw" :src="require('@/assets/xulie/3.jpg')" />
              <!-- <el-image class="imgw" :src="require('@/assets/blank.png')" /> -->
              <!-- <span>{{ $t(`commencont.Coming`) }}</span> -->
            </li>
            <li>
              <el-image class="imgw" :src="require('@/assets/xulie/4.jpg')" />
              <!-- <el-image class="imgw" :src="require('@/assets/blank.png')" /> -->
              <!-- <span>{{ $t(`commencont.Coming`) }}</span> -->
            </li>
            <li>
              <el-image class="imgw" :src="require('@/assets/xulie/5.jpg')" />
              <!-- <el-image class="imgw" :src="require('@/assets/blank.png')" /> -->
              <!-- <span>{{ $t(`commencont.Coming`) }}</span> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="Contactus-Index">
      <div class="w1400">
        <div class="Contactus-Index_left pr">
          <div class="Contactus-Index_left_img"></div>
          <div class="pa Contactus-Index_left_cont">
            <div
              style="
                width: 600px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              "
            >
              <img
                :src="require('@/assets/navli.png')"
                style="margin-right: 5px"
              />
              <b>{{ $t(`contactus.title`) }}</b>
            </div>
            <div style="display: flex; align-items: flex-start">
              <img
                :src="require('@/assets/navli.png')"
                style="margin-right: 5px"
              />
              <samp>{{ $t(`contactus.address`) }}</samp>
            </div>
          </div>
        </div>
        <div class="Contactus-Index_rigth">
          <el-image
            class="imgw"
            :src="autoimgyn == 'en' ? usindexen : usindex"
          />
        </div>
        <div class="Contactus-Index_bottem">
          <ul>
            <!-- <li>
              <span>
                <el-image class="imgw" :src="require('@/assets/tt.png')" />
              </span>
              <p>
                <b>Twitter</b>
                <samp>@xinshengfun</samp>
              </p>
            </li> -->
            <!-- <li>
              <span>
                <el-image class="imgw" :src="require('@/assets/wx.png')" />
              </span>
              <p>
                <b>WhatsApp</b>
                <samp>+852 69370109</samp>
              </p>
            </li> -->
            <li>
              <p>
                <span>
                  <el-image class="imgw" :src="require('@/assets/emil.png')" />
                </span>
                <b>Email</b>
                <samp>crm@youlefun.com</samp>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: `home`,
  setup() {
    const img = require('@/assets/topimg.png')
    const autoimg = require('@/assets/autoindex.png')
    const autoimgen = require('@/assets/en/autoindex.png')
    const gameimg = require('@/assets/gameindex.png')
    const gameimgen = require('@/assets/en/gameindex.png')
    const usindex = require('@/assets/usindex.png')
    const usindexen = require('@/assets/en/usindex.png')
    const autoimgyn = sessionStorage.getItem('lang')
    const li_img = ''
    const router = useRouter()

    const goDetail = (type) => {
      if (type.length < 1) {
        return
      }
      router.push(`/Gameinside?id=${type}`)
    }
    return {
      img,
      li_img,
      autoimg,
      autoimgen,
      gameimg,
      gameimgen,
      usindex,
      usindexen,
      autoimgyn,
      goDetail,
    }
  },
}
</script>

<style scoped>
</style>
